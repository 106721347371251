<template>
  <BCard>
    <div class="text-[#333333] text-[20px] font-semibold">
      Whitelist
    </div>
    <div class="mt-[32px] flex gap-[20px] border-b-[1px] border-[#f4f4f4]">
      <div
        :class="
          activeTab === 'request' &&
            'border-b-[2px] text-[#F95031] border-[#F95031]'
        "
        class="flex gap-[4px] items-center pb-[8px] cursor-pointer"
        @click="handleActiveTab('request')"
      >
        <span class="k-warning-2 text-[16px]" />
        <div>Pengajuan</div>
      </div>

      <div
        :class="
          activeTab !== 'request' &&
            'border-b-[2px] text-[#F95031] border-[#F95031]'
        "
        class="flex gap-[4px] items-center pb-[8px] cursor-pointer"
        @click="handleActiveTab('active')"
      >
        <span class="k-profile-tick text-[16px]" />
        <div>Whitelist Aktif</div>
      </div>
    </div>
    <div class="mt-[16px]">
      <BOverlay
        :show="loadingTable"
        spinner-variant="primary"
        variant="light"
        blur="0"
        opacity=".5"
        rounded="sm"
      >
        <div
          id="table"
          class="mt-1 "
          :class="items.length > 20 && 'scrolled-table'"
          @scroll="handleScroll"
        >
          <BTable
            :items="items"
            :fields="fields"
            show-empty
            empty-text="Tidak ada data yang ditampilkan"
            responsive
          >
            <template #cell(date)="data">
              <div>
                <div>
                  Request :
                  <span class="text-[#333333]">{{
                    DAY_MONTH_YEAR(data.item.requested_at)
                  }}</span>
                </div>
                <div v-if="data.item.approved_at">
                  Approve :
                  <span class="text-[#333333]">{{
                    DAY_MONTH_YEAR(data.item.approved_at)
                  }}</span>
                </div>
              </div>
            </template>
            <template #cell(name)="data">
              {{ data.item.fullname }}
            </template>
            <template #cell(number_phone)="data">
              <div
                v-if="data.item.no_hp"
                class="text-[#333333] flex gap-4 items-center cursor-pointer"
                @click="toLink(data.item.no_hp)"
              >
                <div>
                  0{{ data.item.no_hp }}
                </div>
                <img
                  class="h-[20px]"
                  src="https://storage.googleapis.com/komerce/icon/whatsapp.svg"
                  alt="whatsapp"
                >
              </div>
              <div v-else>
                -
              </div>
            </template>
            <template #cell(action)="data">
              <div>
                <div
                  v-if="!data.item.approved_at"
                  class="flex gap-1 w-fit items-center px-[6px] cursor-pointer  border-[1px] border-[#E2E2E2] rounded-md"
                  @click="openModalConfirmation(data.item.id)"
                >
                  <div
                    class="text-[#FBA63C] flex gap-2 pr-[6px] items-center py-[4px] border-r-[1px] border-[#E2E2E2]"
                  >
                    <span class="k-warning-2 text-[16px]" />
                    <div>Request</div>
                  </div>
                  <span class="k-arrow-right-2 ml-[3px]" />
                </div>
                <div
                  v-else
                  class="flex gap-1 w-fit items-center px-[6px]  border-[1px] border-[#E2E2E2] rounded-md"
                >
                  <div
                    class="text-[#34A770] flex gap-2 pr-[6px] items-center py-[4px]"
                  >
                    <span class="k-tick-circle text-[16px]" />
                    <div>Done</div>
                  </div>
                </div>
              </div>
            </template>
          </BTable>
        </div>
      </BOverlay>
    </div>
    <ModalConfirmation
      :active-modal-id="activeModalId"
      @refetchListWhitelistData="refetchListWhitelistData"
    />
  </BCard>
</template>

<script>
import { DAY_MONTH_YEAR } from '@/libs/filterDate'
import { newAxiosIns } from '@/libs/axios'
import ModalConfirmation from './ModalConfirmation.vue'
import columnConfig from './config'

export default {
  components: {
    ModalConfirmation,
  },
  data() {
    return {
      DAY_MONTH_YEAR,
      activeModalId: null,
      fields: columnConfig,

      loadingTable: false,
      lastData: false,
      limit: 25,
      offset: 0,
      activeTab: 'request',
      items: [],
    }
  },
  mounted() {
    this.getListWhitelistData()
  },
  methods: {
    refetchListWhitelistData() {
      this.activeTab = 'whitelist'
      this.getListWhitelistData()
    },
    async getListWhitelistData() {
      const url = 'auth/api/v1/whitelist-account'

      try {
        this.loadingTable = true
        const response = await newAxiosIns.request({
          method: 'GET',
          url,
          params: {
            limit: this.limit,
            offset: this.offset,
            status: this.activeTab === 'request' ? 'request' : 'approve',
          },
        })

        this.items = response.data.data
        this.loadingTable = false

        if (this.items.length < this.limit) {
          this.lastData = true
        }
      } catch (error) {
        this.$toast_error({ message: 'Gagal load data' })
        this.loadingTable = false
      }
    },
    handleScroll() {
      const table = document.getElementById('table')
      if (
        table.scrollTop >= table.scrollHeight - table.offsetHeight - 5
        && !this.loadingTable
        && !this.lastData
      ) {
        this.getNextData()
      }
    },
    async getNextData() {
      const url = 'komcards/api/v1/whitelist-account'

      try {
        const response = await newAxiosIns.request({
          method: 'GET',
          url,
          params: {
            limit: this.limit,
            offset: this.offset,
            status: this.activeTab === 'request' ? 'request' : 'approve',
          },
        })

        const { data } = response.data
        this.items.push(...data)
        this.loadingTable = false

        this.offset += data.length

        if (data.length < this.limit) {
          this.lastData = true
        }
      } catch (error) {
        this.$toast_error({ message: 'Gagal load data' })
        this.loadingTable = false
      }
    },
    toLink(number) {
      window.open(`https://wa.me/62${number}`)
    },
    handleActiveTab(tab) {
      this.limit = 25
      this.offset = 0
      this.activeTab = tab
      this.getListWhitelistData()
    },
    openModalConfirmation(id) {
      this.activeModalId = id
      this.$bvModal.show('modal-confirmation')
    },
  },
}
</script>

<style scoped>
.scrolled-table {
  height: calc(100vh - 250px);
  overflow-y: scroll;
}
</style>
